import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { Link } from "gatsby"

import Header from "./header"

const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 0 1.0875rem 1.45rem;
`

const Content = styled.main`
  margin-bottom: 1.0874rem;
  padding-bottom: 1.0874rem;
`

const Footer = styled.footer`
  border-top: 1px solid #eee;
  padding-top: 1.0874rem;
  clear: both;
  font-size: 0.95rem;
`

const FooterCopyright = styled.div`
  float: right;
`

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <Container>
        <Content>{children}</Content>
        <Footer>
          <Link to="/">Home</Link> | <Link to="/reviews">Reviews</Link> |{" "}
          <Link to="/info">Info</Link> | <Link to="/tutorials">Tutorials</Link>{" "}
          | <Link to="/about">About</Link>
          <FooterCopyright>© {new Date().getFullYear()}, AJ</FooterCopyright>
        </Footer>
      </Container>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
