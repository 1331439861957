import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const Container = styled.header`
  margin-bottom: 1.45rem;
  border-bottom: 1px solid #ccc;
`
const Content = styled.div`
  margin: 0 auto;
  maxwidth: 960;
  padding: 1.45rem 1.0875rem;
  padding-bottom: 0.5rem;
`
const LogoContent = styled.h1`
  margin: 0;
`
const QuickNav = styled.div`
  margin: 0;
`
const noUnderline = css`
  text-decoration: none;
  background-image: none;
  text-shadow: none;
`

const link = css`{
  color: #444;
`

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      logo: file(relativePath: { eq: "aj.png" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Content>
        <LogoContent>
          <Link to="/" css={noUnderline}>
            <Img
              fixed={data.logo.childImageSharp.fixed}
              alt={data.site.siteMetadata.title}
            />
          </Link>
        </LogoContent>
        <QuickNav>
          <Link to="/reviews" css={[noUnderline, link]}>
            Reviews
          </Link>
          .{" "}
          <Link to="/info" css={[noUnderline, link]}>
            Info
          </Link>
          .{" "}
          <Link to="/tutorials" css={[noUnderline, link]}>
            Tutorials
          </Link>
          .
        </QuickNav>
      </Content>
    </Container>
  )
}

export default Header
